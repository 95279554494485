import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { FormProvider, useForm } from 'react-hook-form'

import * as Modal from '_components/modal/generic-modal'
import { BasicInput, Autocomplete } from '_/components/inputs'
import * as Input from '_/components/inputs/Input'
import { Switch, Typography } from '@material-ui/core'
import { Grid } from '@mui/material'

import useStyles from './styles'
import usePageStyles from '../../styles'
import { useDispatch, useSelector } from 'react-redux'
import { getCategoryList, getProblemList, getServiceList } from '_/modules/service-orders/actions'
import { useToast } from '_/hooks/use-toast'
import { useParams } from '@reach/router'
import { useDebounce } from 'use-debounce'
import { round } from '_/views/finance/utils/functions'
import {
  getCategoryListLoadingSelector,
  getProblemListLoadingSelector,
  getServiceListLoadingSelector,
} from '_/modules/service-orders/selectors'

export function ServiceModal({
  budgetServices,
  clearOnClose,
  defaultValues,
  loading,
  onClose,
  open,
  onSaveButtonClick,
}) {
  const styles = useStyles()
  const pageStyles = usePageStyles()
  const dispatch = useDispatch()
  const getServiceListLoading = useSelector(getServiceListLoadingSelector)
  const getProblemListLoading = useSelector(getProblemListLoadingSelector)
  const getCategoryListLoading = useSelector(getCategoryListLoadingSelector)
  const { serviceOrderId } = useParams()

  const { showToast } = useToast()

  const [servicesList, setServicesList] = useState([])
  const [problemsList, setProblemsList] = useState([])
  const [categoryList, setCategoryList] = useState([])

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      filterJustCategories: true,
    },
  })

  const { setValue, watch, handleSubmit, reset, register } = methods

  const filterJustCategories = watch('filterJustCategories') ?? true
  const filterProblem = watch('filterProblem')
  const filterCategory = watch('filterCategory')
  const quantity = watch('quantity')?.toString()?.replace(/\./g, '')?.replace(',', '.') ?? '1'
  const filterTextWatch = watch('filterText') ?? ''

  const material =
    watch('material')?.toString()?.replace(/R\$ /g, '')?.replace(/\./g, '')?.replace(',', '.') ??
    '0'
  const labor =
    watch('labor')?.toString()?.replace(/R\$ /g, '')?.replace(/\./g, '')?.replace(',', '.') ?? '0'

  const [searchValue] = useDebounce(filterTextWatch, 1000)

  const fetchServiceFiltered = useCallback(() => {
    if (
      filterJustCategories !== undefined ||
      filterProblem?.id !== undefined ||
      filterCategory?.id !== undefined ||
      searchValue !== ''
    ) {
      dispatch(
        getServiceList({
          active: true,
          serviceorderCategory: filterJustCategories,
          serviceOrderId,
          problems: filterProblem?.id,
          category: filterCategory?.id,
          filterText: searchValue,
        })
      )
        .then(data => {
          setServicesList(data)
        })
        .catch(() => {
          showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os registros.' })
        })
    }
  }, [filterJustCategories, filterProblem?.id, filterCategory?.id, searchValue])

  useEffect(() => {
    fetchServiceFiltered()
  }, [filterJustCategories, filterProblem, filterCategory, searchValue])

  useEffect(() => {
    dispatch(
      getProblemList({
        active: true,
      })
    )
      .then(res => setProblemsList(res))
      .catch(() => {
        showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os problemas.' })
      })

    dispatch(
      getCategoryList({
        isActive: true,
      })
    )
      .then(res => {
        setCategoryList(res)
      })
      .catch(() => {
        showToast({ type: 'error', message: 'Ocorreu um erro ao buscar as categorias.' })
      })

    dispatch(
      getServiceList({
        active: true,
        serviceorderCategory: filterJustCategories,
        serviceOrderId,
      })
    )
      .then(data => {
        setServicesList(data)
      })
      .catch(() => {
        showToast({ type: 'error', message: 'Ocorreu um erro ao buscar os registros.' })
      })
  }, [])

  const parseCurrency = value => {
    if (!value) return 0
    if (typeof value === 'number') return value

    const parsedValue = parseFloat(value)
    return isNaN(parsedValue) ? parseFloat(value.replace(/[^\d,]/g, '')) : parsedValue
  }

  // /* Update fields accordingly to the service field value */
  const customAutocompleteOnChange = useCallback(
    (_, value, reason) => {
      if (reason === 'select-option' && value) {
        const hasServices = budgetServices?.length > 0

        const { gridDesc, diagnosisDesc, solutionDesc, idealMaterial, idealLabor, complexity } =
          value
        setValue('gridDesc', gridDesc)
        setValue('diagnosisDesc', diagnosisDesc)
        setValue('solutionDesc', solutionDesc)

        if (!hasServices || complexity >= 3) {
          setValue('labor', parseCurrency(idealLabor))
          setValue('material', parseCurrency(idealMaterial))
          return
        }

        // -------------- After effects
        const fullBudgetServices = servicesList?.filter(service => {
          return budgetServices?.some(item => {
            return item.service === service.id
          })
        })

        const hasBiggerIdealLabor = fullBudgetServices?.some(item => {
          // Budget has items with higher idealLabor and complexity > 2 (higher than or equal to 3)
          return parseFloat(item?.idealLabor) >= parseFloat(idealLabor) && item?.complexity > 2
        })

        if (hasBiggerIdealLabor) {
          setValue('labor', parseCurrency(value.additionalLabor))
          setValue('material', parseCurrency(value.additionalPrice - value.additionalLabor))
        } else {
          setValue('labor', parseCurrency(idealLabor))
          setValue('material', parseCurrency(idealMaterial))
        }
      }
    },
    [servicesList, budgetServices]
  )

  const totalPrice = useMemo(() => {
    return round(parseFloat(material) + parseFloat(labor), 2)
  }, [material, labor])

  const onSubmit = useCallback(
    data => {
      const newTotalPrice =
        totalPrice * parseCurrency(data.quantity?.toString()?.replace(/\./g, '')?.replace(',', '.'))

      const payload = {
        ...data,
        material: data.material
          ?.toString()
          ?.replace(/R\$ /g, '')
          ?.replace(/\./g, '')
          ?.replace(',', '.'),
        labor: data.labor?.toString()?.replace(/R\$ /g, '')?.replace(/\./g, '')?.replace(',', '.'),
        quantity: data.quantity?.toString()?.replace(/\./g, '')?.replace(',', '.'),
        price: totalPrice,
        totalPrice: round(newTotalPrice, 2),
      }

      if (defaultValues) onSaveButtonClick(defaultValues, payload) // update service
      else onSaveButtonClick(payload) // post new service

      reset({
        service: null,
        gridDesc: '',
        diagnosisDesc: '',
        solutionDesc: '',
        material: 0,
        labor: 0,
      })
    },
    [onSaveButtonClick, defaultValues, totalPrice, parseCurrency]
  )

  const localOnClose = () => {
    onClose()
    if (clearOnClose)
      reset({
        service: null,
        gridDesc: '',
        diagnosisDesc: '',
        solutionDesc: '',
        material: 0,
        labor: 0,
      })
  }

  return (
    <Modal.Root open={open} onClose={localOnClose} maxWidth="md" keepMounted>
      <Modal.TitleModal title="Serviço" />

      <Modal.Content className={styles.modalContent}>
        <FormProvider {...methods}>
          <form className={styles.form} onSubmit={e => e.preventDefault()}>
            <div className={styles.column}>
              <div className={styles.filterField}>
                <Typography className={styles.title}>Filtros de serviços</Typography>
                <div className={styles.filterBox}>
                  <Typography>Só das categorias</Typography>
                  <Switch
                    className={styles.switches}
                    defaultChecked
                    checked={filterJustCategories}
                    onChange={e => {
                      setValue('filterJustCategories', e.target.checked)
                    }}
                    {...register('filterJustCategories')}
                  />
                </div>

                <div className={styles.row}>
                  <Autocomplete
                    options={problemsList || []}
                    getOptionLabel={option => option.name}
                    label="Problema"
                    name="filterProblem"
                    multiple={false}
                    loading={getProblemListLoading}
                  />

                  <Autocomplete
                    options={categoryList || []}
                    getOptionLabel={option => option.name}
                    label="Categoria/subcategoria"
                    name="filterCategory"
                    multiple={false}
                    loading={getCategoryListLoading}
                  />

                  <Input.Root name="filterText">
                    <Input.Label labelClasses={styles.label} name="filterText">
                      Filtro adicional
                    </Input.Label>
                    <Input.ControllerText rules={{ maxLength: 520 }} name="filterText" />
                  </Input.Root>
                </div>
              </div>
              <Autocomplete
                options={servicesList || []}
                getOptionLabel={option => option.name}
                label="Serviço"
                name="service"
                required
                multiple={false}
                loading={getServiceListLoading}
                customOnChange={customAutocompleteOnChange}
              />
            </div>
            <BasicInput required name="gridDesc" label="Texto da grid" rules={{ maxLength: 510 }} />
            <div className={styles.row}>
              <Input.Root name="filterText">
                <Input.LabelTextarea required name="diagnosisDesc">
                  Diagnóstico
                </Input.LabelTextarea>
                <Input.ControllerTextarea
                  required
                  rules={{
                    required: { value: true, message: 'Esse campo é obrigatório', maxLength: 1020 },
                  }}
                  name="diagnosisDesc"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  maxRows={6}
                  minRows={6}
                  textArea
                  placeholder="Escreva seu diagnóstico…"
                />
                <Input.ErrorMessage name="diagnosisDesc" />
              </Input.Root>
              <Input.Root name="filterText">
                <Input.LabelTextarea required name="solutionDesc">
                  Solução
                </Input.LabelTextarea>
                <Input.ControllerTextarea
                  required
                  rules={{
                    required: { value: true, message: 'Esse campo é obrigatório', maxLength: 1020 },
                  }}
                  name="solutionDesc"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  maxRows={6}
                  minRows={6}
                  textArea
                  placeholder="Escreva sua solução..."
                />
                <Input.ErrorMessage name="solutionDesc" />
              </Input.Root>
            </div>
            <div className={styles.row}>
              <Input.Root name="material" defaultValue={0}>
                <Input.Label labelClasses={styles.labelMoney} name="material" required>
                  Material
                </Input.Label>

                <Input.ControllerNumber
                  required
                  prefix="R$ "
                  rules={{
                    required: { value: true, message: 'Esse campo é obrigatório' },
                  }}
                  name="material"
                />

                <Input.ErrorMessage name="material" />
              </Input.Root>

              <Input.Root name="labor" defaultValue={0}>
                <Input.Label labelClasses={styles.labelMoney} name="labor" required>
                  Mão de obra
                </Input.Label>

                <Input.ControllerNumber
                  required
                  prefix="R$ "
                  rules={{
                    required: { value: true, message: 'Esse campo é obrigatório' },
                  }}
                  name="labor"
                />

                <Input.ErrorMessage name="labor" />
              </Input.Root>
            </div>
            <div className={styles.row}>
              <Grid
                className={`${pageStyles.totalValueContainer} ${styles.modalTotalValueContainer}`}
              >
                <Typography>
                  Total:
                  <b>
                    {totalPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                  </b>
                </Typography>
              </Grid>

              <Input.Root style={{ marginTop: '15px' }} name="quantity" defaultValue={1}>
                <Input.Label name="quantity" required>
                  Quantidade
                </Input.Label>

                <Input.ControllerNumber
                  required
                  rules={{
                    required: { value: true, message: 'Esse campo é obrigatório' },
                    validate: value => {
                      const floatValue =
                        value &&
                        ((typeof value === 'number' && parseFloat(value)) ||
                          parseFloat(value?.replace(/\./g, '')?.replace(',', '.')))

                      if (floatValue > 999999999.9999) {
                        return 'O número precisa ser menor que 999.999.999.99'
                      }

                      if (floatValue <= 0) {
                        return 'O número precisa ser maior que 0'
                      }

                      return true
                    },
                  }}
                  name="quantity"
                />

                <Input.ErrorMessage name="quantity" />
              </Input.Root>

              <Grid
                className={`${pageStyles.totalValueContainer} ${styles.modalTotalValueContainer}`}
              >
                <Typography>
                  Total Final:
                  <b>
                    {(totalPrice * parseFloat(quantity)).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </b>
                </Typography>
              </Grid>
            </div>
          </form>
        </FormProvider>
      </Modal.Content>

      <Modal.Actions>
        <Modal.ButtonRed onClick={localOnClose} disabled={loading}>
          Cancelar
        </Modal.ButtonRed>
        <Modal.ButtonFullBlue onClick={handleSubmit(onSubmit)} disabled={loading}>
          {' '}
          Salvar
        </Modal.ButtonFullBlue>
      </Modal.Actions>
    </Modal.Root>
  )
}
