/* eslint-disable camelcase */
import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { Collapse, Grid, Typography, Divider } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation, redirectTo } from '@reach/router'
import { parse } from 'query-string'
import { Alert, Dialog, Preview, useConfirm } from '@refera/ui-web'
import { Attachments } from '_/components/refera-ui'
import { Danger as DangerIcon } from '@refera/ui-icons'

import Svg from '_components/svg'
import Snackbar from '_components/snackbar'
import Header, { HEADER_THEME } from '_components/header'
import Button from '_components/button'
import Footer from '_/components/footer'
import { getBudgetByIdSelector, getPayerOptionsSelector } from '_modules/budget/selectors'
import { getServiceOrderByID } from '_modules/service-orders/selectors'
import { getServiceOrder } from '_modules/service-orders/actions'
import { getHistoryLogsSelector } from '_modules/history-logs/selectors'
import { getHistoryLogs } from '_modules/history-logs/actions'
import {
  getBudget,
  clientRefuseBudget,
  clientApproveBudget,
  CLIENT_APPROVE_BUDGET,
  getPayerOptions,
} from '_modules/budget/actions'
import { authenticateToken } from '_modules/authentication/actions'
import {
  authenticateTokenLoadingSelector,
  authenticateTokenErrorSelector,
} from '_modules/authentication/selectors'
import { getInstallmentRules } from '_/modules/finance/actions'
import { getInstallmentRulesSelector } from '_/modules/finance/selectors'
import { usePrevious } from '_hooks/use-previous'
import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'
import Select from '_components/common/select'
import filePage from '_assets/images/filePage.png'
import Union from '_assets/icons/ic-union.svg'

import {
  INFO_MESSAGES,
  stepStatusApprovedBudget,
  stepStatusBudgetIsNotApprovedYet,
  stepStatusBudgetIsNotRefusedYet,
  stepStatusRefusedBudget,
  stepStatusRefusedOrApprovedBudget,
} from './utils'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'
import { useToast } from '_/hooks/use-toast'

import useStyles from './styles'

const BudgetVisualization = () => {
  const dispatch = useDispatch()
  const { budgetId, serviceOrderId } = useParams()
  const { search } = useLocation()
  const budget = useSelector(getBudgetByIdSelector(budgetId))
  const serviceOrder = useSelector(state => getServiceOrderByID(state, serviceOrderId))
  const historyLogs = useSelector(getHistoryLogsSelector)
  const options = useSelector(getPayerOptionsSelector)
  const installmentRules = useSelector(getInstallmentRulesSelector)
  const { verification_token } = parse(search)
  const { showToast } = useToast()

  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [errorModal, setErrorModal] = useState({
    open: false,
    title: '',
    setIsOpen: () => {},
  })
  const [alert, setAlert] = useState({
    isOpen: false,
    title: '',
  })
  const { isConfirmed } = useConfirm()
  const [dialogMessage, setDialogMessage] = useState('')
  const [approvedOrRefused, setApprovedOrRefused] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [installments, setInstallments] = useState('')

  const isAuthenticatingToken = useSelector(authenticateTokenLoadingSelector)
  const authenticateTokenError = useSelector(authenticateTokenErrorSelector)
  const wasAuthenticatingToken = usePrevious(isAuthenticatingToken)

  const cannotEditBudget = useMemo(() => {
    const areButtonsDisabled =
      stepStatusRefusedOrApprovedBudget.includes(serviceOrder?.stepStatus) || buttonsDisabled

    if (areButtonsDisabled) {
      const message =
        stepStatusApprovedBudget.includes(serviceOrder?.stepStatus) ||
        approvedOrRefused === 'approved'
          ? INFO_MESSAGES.CANNOT_CHANGE_APPROVED_BUDGET
          : INFO_MESSAGES.CANNOT_CHANGE_REFUSED_BUDGET

      setAlert({
        isOpen: true,
        title: message,
      })
    }
    return areButtonsDisabled
  }, [serviceOrder?.stepStatus, stepStatusRefusedOrApprovedBudget, buttonsDisabled, setAlert])

  const budgetPayer = useMemo(() => {
    return budget?.toJS()?.budgetPayer || {}
  }, [budget?.toJS()?.budgetPayer])

  const styles = useStyles({ cannotEditBudget })

  useEffect(() => {
    if (!installmentRules) dispatch(getInstallmentRules({ budgetId, installment: 9 }))
  }, [installmentRules])

  useEffect(() => {
    dispatch(
      authenticateToken({
        verification_token,
      })
    )
  }, [dispatch, search, verification_token])

  useEffect(() => {
    if (!isAuthenticatingToken && wasAuthenticatingToken) {
      if (authenticateTokenError?.size) {
        redirectTo('/link-expirado')
      } else {
        dispatch(getBudget(serviceOrderId, budgetId))
      }
      dispatch(getServiceOrder(serviceOrderId))
      dispatch(getHistoryLogs(serviceOrderId))
      dispatch(getPayerOptions(serviceOrderId, budgetId))
    }
  }, [
    authenticateTokenError?.size,
    budgetId,
    dispatch,
    isAuthenticatingToken,
    serviceOrderId,
    wasAuthenticatingToken,
  ])

  useEffect(() => {
    if (budgetPayer) {
      setPaymentMethod(budgetPayer[0]?.formOfPayment || '')
      setInstallments(budgetPayer[0]?.installmentQuantity || '')
    }
  }, [Object?.keys(budgetPayer)?.length])

  const formatterNumberOnly = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  const handleConfirmApproveBudget = useCallback(async () => {
    const payload = {
      installmentQuantity: installments,
      formOfPayment: paymentMethod,
    }

    await Promise.resolve(dispatch(clientApproveBudget(serviceOrderId, budgetId, payload)))
    setApprovedOrRefused('approved')
    setButtonsDisabled(true)
    setDialogMessage('')
  }, [
    dispatch,
    setApprovedOrRefused,
    setButtonsDisabled,
    setDialogMessage,
    installments,
    paymentMethod,
  ])

  const handleApproveModal = useCallback(async () => {
    setDialogMessage(INFO_MESSAGES.CONFIRM_ACTION)
    const confirmed = await isConfirmed()

    if (confirmed) {
      handleConfirmApproveBudget()
    }
  }, [isConfirmed, setDialogMessage, handleConfirmApproveBudget])

  const handleApproveBudget = useCallback(async () => {
    const isBudgetApproved = stepStatusApprovedBudget.includes(serviceOrder?.stepStatus)
    const isBudgetNotApprovedYet = stepStatusBudgetIsNotApprovedYet.includes(
      serviceOrder?.stepStatus
    )

    if (serviceOrder?.activeBudget !== budget.id) {
      setErrorModal({
        open: true,
        title: INFO_MESSAGES.INVALID_BUDGET,
        setIsOpen: () => setErrorModal({ open: false, title: '' }),
      })
    } else if (isBudgetApproved) {
      setErrorModal({
        open: true,
        title: INFO_MESSAGES.ALREADY_APPROVED_BUDGET,
        setIsOpen: () => setErrorModal({ open: false, title: '' }),
      })
    } else if (isBudgetNotApprovedYet) {
      await handleApproveModal()
    } else {
      setErrorModal({
        open: true,
        title: INFO_MESSAGES.CANNOT_EDIT_ERROR,
        setIsOpen: () => setErrorModal({ open: false, title: '' }),
      })
    }
  }, [historyLogs, serviceOrder, handleApproveModal])

  const defaultPreviewState = {
    visible: false,
    selectedIndex: undefined,
  }
  const [previewState, setPreviewState] = useState(defaultPreviewState)
  const PreviewComponent = useMemo(
    () => (
      <Preview
        open={previewState.visible}
        onClose={() => setPreviewState(() => defaultPreviewState)}
        selectedIndex={previewState.selectedIndex}
        images={
          budget?.toJS()?.budgetPictures &&
          budget?.toJS()?.budgetPictures?.map((item, index) =>
            item.id
              ? {
                  id: item.id,
                  url: item.picture,
                }
              : {
                  id: index,
                  url: URL.createObjectURL(item),
                }
          )
        }
      />
    ),
    [previewState, budget?.toJS()?.budgetPictures]
  )

  const handleConfirmCancelApproveBudget = () => {
    dispatch(clientRefuseBudget({ serviceOrderId, budgetId }))
      .then(() => showToast({ type: 'success' }))
      .catch(err => {
        const message = formatErrorMessage(err)
        showToast({ type: 'error', message })
      })
    setApprovedOrRefused('refused')
    setButtonsDisabled(true)
    setDialogMessage('')
  }

  const handleRefuseModal = useCallback(async () => {
    setDialogMessage(INFO_MESSAGES.CONFIRM_ACTION)
    const confirmed = await isConfirmed()

    if (confirmed) {
      handleConfirmCancelApproveBudget()
    }
  }, [isConfirmed, setDialogMessage, handleConfirmCancelApproveBudget])

  const handleCancelApproveBudget = useCallback(() => {
    const isBudgetRefused = stepStatusRefusedBudget.includes(serviceOrder?.stepStatus)
    const isBudgetNotRefusedYet = stepStatusBudgetIsNotRefusedYet.includes(serviceOrder?.stepStatus)

    if (serviceOrder?.activeBudget !== budget.id) {
      setErrorModal({
        open: true,
        title: INFO_MESSAGES.INVALID_BUDGET,
        setIsOpen: () => setErrorModal({ open: false, title: '' }),
      })
    } else if (isBudgetRefused) {
      setErrorModal({
        open: true,
        title: INFO_MESSAGES.ALREADY_REFUSED_BUDGET,
        setIsOpen: () => setErrorModal({ open: false, title: '' }),
      })
    } else if (isBudgetNotRefusedYet) {
      handleRefuseModal()
    } else {
      setErrorModal({
        open: true,
        title: INFO_MESSAGES.CANNOT_EDIT_ERROR,
        setIsOpen: () => setErrorModal({ open: false, title: '' }),
      })
    }
  }, [historyLogs, serviceOrder, handleRefuseModal])

  const handleInstallmentQuantity = useMemo(() => {
    if (installmentRules) return installmentRules?.installment

    return null
  }, [installmentRules, budget])

  const budgetPictures = useMemo(() => {
    if (!budget?.toJS()?.budgetPictures?.length) {
      return []
    }

    return budget?.toJS()?.budgetPictures?.map((picture, index) => {
      if ('picture' in picture) {
        return { file: picture.picture, id: index }
      }
      return { file: picture, type: 'file', id: index }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budget?.toJS()?.budgetPictures?.length])

  const isApprovalButtonDisabled = useMemo(() => {
    return !paymentMethod || !installments
  }, [paymentMethod, installments])

  const DEFAULT_OPTION_SELECT = {
    value: '',
    label: 'Selecione',
  }

  const paymentMethodOptions = useMemo(
    () => options?.paymentMethod?.map(item => ({ value: item?.status, label: item?.option })) ?? [],
    [options?.paymentMethod]
  )
  const installmentOptions = useMemo(() => {
    if (installmentRules && options?.installmentOptions) {
      const maxInstallment = handleInstallmentQuantity
      if (maxInstallment) {
        return options.installmentOptions.reduce((acc, item) => {
          if (item?.status <= maxInstallment) {
            acc.push({ value: item?.status, label: item?.option })
          }
          return acc
        }, [])
      }
    }

    return (
      options?.installmentOptions?.map(item => ({ value: item?.status, label: item?.option })) ?? []
    )
  }, [installmentRules, options?.installmentOptions, handleInstallmentQuantity])

  return (
    <>
      <Header theme={HEADER_THEME.NO_BUTTONS} className={styles.header} />
      <Grid className={styles.container}>
        {PreviewComponent}
        <Grid container className={styles.content}>
          <Grid className={styles.pic}>
            <img src={filePage} alt="file icon" className={styles.fileIcon} />
          </Grid>
          <Typography component="h1" variant="h1" className={styles.title}>
            {`Orçamento Refera - ${budgetId}`}
          </Typography>
          {alert?.isOpen && (
            <Collapse in={alert?.isOpen} className={styles.alert}>
              <Alert severity="info" title={alert.title} />
            </Collapse>
          )}
          <Grid className={styles.div}>
            <Typography component="h4" variant="h4" className={styles.diagnosis}>
              Diagnóstico do problema
            </Typography>
            <Typography className={styles.diagnosisText}>{budget?.diagnosis}</Typography>
            <Typography component="h4" variant="h4" className={styles.solution}>
              Solução para o problema
            </Typography>
            <Typography className={styles.diagnosisText}>{budget?.proposedSolution}</Typography>
          </Grid>
          <Grid className={styles.div}>
            <Typography component="h4" variant="h4" className={styles.tableTitle}>
              Orçamento detalhado
            </Typography>
            <Grid className={styles.tableList}>
              <Grid className={styles.leftList}>
                <Typography className={styles.listHeaderDescription}>Descrição</Typography>
                <ul>
                  {budget?.toJS()?.budgetItems?.map(item => {
                    return (
                      <li className={styles.leftListItems} key={item?.id}>
                        {item?.description}
                      </li>
                    )
                  })}
                </ul>
              </Grid>
              <Grid className={styles.rightList}>
                <Typography className={styles.listHeaderPrice}>R$</Typography>
                <ul>
                  {budget?.toJS()?.budgetItems?.map(item => {
                    return (
                      <li className={styles.rightListItems} key={item?.id}>
                        {formatterNumberOnly.format(
                          item?.price *
                            ((parseFloat(budget?.referaCompletionPercentage) +
                              parseFloat(budget?.agencyCompletionPercentage)) /
                              100 +
                              1)
                        )}
                      </li>
                    )
                  })}
                </ul>
              </Grid>
            </Grid>
            <Grid className={styles.totalPrice}>
              <Typography className={styles.totalPriceText}>Total</Typography>
              <Typography className={styles.totalPriceValue}>
                {formatterNumberOnly.format(budget?.totalPrice)}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid className={styles.guarantee}>
            <Svg className={styles.guaranteeText} icon={Union} />
            <Typography className={styles.guaranteeText}>
              {`O serviço tem garantia de ${budget?.warrantyMonths} meses`}
            </Typography>
          </Grid>
          {/* TODO after the BE integration */}
          {/* <Grid className={styles.duration}>
              <AccessTime color="primary" />
              <Typography className={styles.durationText}>
                O serviço terá duração de 5 horas
              </Typography>
            </Grid> */}
          {/* TODO after the BE integration */}
          {/* <Grid className={styles.employees}>
              <PeopleOutline color="primary" />
              <Typography className={styles.employeesText}>
                02 funcionários irão realizar o serviço
              </Typography>
            </Grid> */}
          <Divider />
          <Grid className={styles.mediaSection}>
            <Typography component="h4" variant="h4" className={styles.mediaTitle}>
              Fotos ou vídeo do problema
            </Typography>
            <Grid className={styles.mediaWrapper}>
              <Attachments
                readOnly
                className={styles.attachment}
                files={budgetPictures}
                onItemClick={index => {
                  setPreviewState(() => ({
                    visible: true,
                    selectedIndex: index,
                  }))
                }}
              />
            </Grid>
            <Grid />
            <Typography component="h4" variant="h4" className={styles.paymentTitle}>
              Pagamento
            </Typography>
            <Grid className={styles.paymentContainer}>
              <Grid className={styles.selectContainer}>
                <Grid className={styles.selectInput}>
                  <Typography className={styles.selectLabel}> Forma de pagamento</Typography>
                </Grid>

                <Select
                  required
                  defaultValue={paymentMethod}
                  value={paymentMethod}
                  disabled={cannotEditBudget}
                  className={styles.selectInput}
                  name="paymentMethod"
                  onChange={event => setPaymentMethod(event.target.value)}
                  options={[DEFAULT_OPTION_SELECT, ...paymentMethodOptions]}
                />
              </Grid>
              <Grid className={styles.selectContainer}>
                <Grid className={styles.selectInput}>
                  <Typography className={styles.selectLabel}>Parcelamento</Typography>
                </Grid>
                <Select
                  required
                  defaultValue={installments}
                  value={installments}
                  disabled={cannotEditBudget}
                  className={styles.selectInput}
                  name="installments"
                  onChange={event => setInstallments(event.target.value)}
                  options={[DEFAULT_OPTION_SELECT, ...installmentOptions]}
                />
                <Typography className={styles.helpText}>
                  Serão cobrados juros dependendo do parcelamento escolhido
                </Typography>
              </Grid>
            </Grid>
            <Grid className={styles.buttons}>
              <Button
                color="primary"
                variant="contained"
                className={styles.button}
                onClick={handleApproveBudget}
                disabled={isApprovalButtonDisabled || cannotEditBudget}
              >
                Aceitar Orçamento
              </Button>
              <Button
                color="red"
                variant="outlined"
                className={styles.button}
                onClick={handleCancelApproveBudget}
                disabled={cannotEditBudget}
              >
                Recusar Orçamento
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <ConfirmRemoveDialog message={dialogMessage} />
        {errorModal.open && (
          <Dialog
            open={errorModal?.open}
            icon={DangerIcon}
            type="info"
            subject={errorModal?.title}
            onApprove={errorModal?.setIsOpen}
          />
        )}
        <Snackbar action={[CLIENT_APPROVE_BUDGET.ACTION]} />
        <Footer className={styles.footer} />
      </Grid>
    </>
  )
}

export default BudgetVisualization
